import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import cx from "classnames";

import { useCommonStyles } from "../../../../utils/loan-application.styles";
import { primaryColor, borderColor } from "../../../../scss/colors.scss";
import Button from "../../components/Button";
import { getLabel } from "../../../../utils/loan-application.utils";
import { calculateLvr } from "../../../../utils/scenario-utils";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: "24px",
    borderRadius: "3px",
    width: "100%",
    border: `2px solid ${borderColor}`,
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  editButton: {
    padding: "5px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "2px 8px",
    },
  },
  statusBox: {
    border: `1px solid ${primaryColor}`,
    padding: "20px 25px",
    borderRadius: "2px",
    [theme.breakpoints.down("xs")]: {
      padding: "12px 16px",
      display: "flex",
      alignItems: "center",
    },
  },
}));

const PropertySummaryCard = ({ propertyData, onEdit }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const lvrPercent = calculateLvr(
    propertyData.estimateValue,
    propertyData.currentOwing
      ? propertyData.currentOwing
      : propertyData.originalLoanAmount
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap="24px"
      classes={{ root: classes.cardContainer }}
    >
      <Box display="flex" justifyContent="space-between" gridColumnGap="48px">
        <Typography
          classes={{
            root: cx(
              commonClasses.fontSize18,
              commonClasses.fontWeightBold,
              commonClasses.textOverflowEllipsis
            ),
          }}
        >
          {propertyData.address}
        </Typography>
        <Button classes={classes.editButton} onClick={onEdit}>
          Edit
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" gridColumnGap="48px">
        <NumberFormat
          value={propertyData.estimateValue}
          displayType="text"
          thousandSeparator=","
          decimalScale={0}
          fixedDecimalScale={false}
          renderText={(value) => (
            <Typography
              classes={{
                root: commonClasses.fontSize18,
              }}
            >
              Value Est: ${value}
            </Typography>
          )}
        />

        {propertyData.hasMortgage && (
          <NumberFormat
            value={propertyData.currentOwing || propertyData.originalLoanAmount}
            displayType="text"
            thousandSeparator=","
            decimalScale={0}
            fixedDecimalScale={false}
            renderText={(value) => (
              <Typography
                classes={{
                  root: cx(commonClasses.fontSize18, commonClasses.textRight),
                }}
              >
                Loan: ${value}
              </Typography>
            )}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" gridColumnGap="48px">
        <Box classes={{ root: classes.statusBox }}>
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
          >
            {getLabel(propertyData.category)}
          </Typography>
        </Box>
        <Box classes={{ root: classes.statusBox }}>
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
          >
            LVR {lvrPercent}%
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PropertySummaryCard;
